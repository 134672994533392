import React, { useState } from 'react';
import axios from 'axios';
import './SearchBar.css';
import { Link } from 'react-router-dom';

const SearchBar = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [searched, setSearched] = useState(false);

  const handleSearch = async () => {
    if (!searchTerm.trim()) return; // Check for empty search term

    setLoading(true);
    setError('');
    setSearched(true);
    setResults([]); // Clear previous results

    try {
      const response = await axios.get(`https://api.blissfullhimalaya.com/api/search?term=${encodeURIComponent(searchTerm.trim())}`);
      setResults(response.data);
    } catch (err) {
      setError('An error occurred while fetching the search results.');
      console.error(err);
    } finally {
      setLoading(false);
    }

    setSearchTerm(''); // Clear the search term after search
  };

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
    if (e.target.value.trim() === '') {
      setResults([]);
      setSearched(false);
    }
  };

  const getLink = (type, uuid, title) => {
    switch (type) {
      case 'article':
        return `/article/${encodeURIComponent(title)}`; // Ensure title is encoded
      case 'activity':
        return `/activities/${uuid}`;
      case 'blog':
        return `/blog/${uuid}`;
      default:
        return '#';
    }
  };

  return (
    <div className="search-container">
      <div className="top">
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleInputChange}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              handleSearch();
            }
          }}
        />
        <button onClick={handleSearch} disabled={loading}>
          {loading ? 'Searching...' : 'Search'}
        </button>
      </div>

      <div className="search-results">
        {error && <p className="error-message">{error}</p>}

        {searched && (
          <div className="results-dropdown">
            {results.length > 0 ? (
              results.map((result, index) => (
                <div key={index} className="result-item">
                  <p className='close' onClick={() => setSearched(false)}>X</p>
                  <Link to={getLink(result.type, result.uuid, result.title || result.name)} className="result-link">
                    <h4 className="result-title">{result.title || result.name}</h4>
                    <p className="result-type">({result.type})</p>
                  </Link>
                </div>
              ))
            ) : (
              <div className="no-results">
                <p>No results found</p>
                <p className='close' onClick={() => setSearched(false)}>X</p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchBar;
