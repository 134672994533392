import React from 'react';
import './AboutUsBanner.css';
import AboutUsImage from '../assets/banner.jpg'; // Replace with your banner image

const AboutUsBanner = () => {
  return (
    <div className="about-us-banner">
      <img src={AboutUsImage} alt="About Us Banner" className="banner-image" />
      <div className="banner-content">
        <h1 className="banner-title">About Us</h1>
        <p className="banner-subtitle">Get to know our team and our mission</p>
        <p className="banner-description">
          At Blissful Himalaya, we are dedicated to providing exceptional travel experiences across Nepal. Our team of passionate travel experts is committed to delivering authentic and unforgettable journeys, helping you explore the most stunning landscapes and vibrant cultures. Learn more about our story and the people behind the adventures.
        </p>
      </div>
    </div>
  );
};

export default AboutUsBanner;
