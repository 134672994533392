import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Annapurna from "../assets/Annapurna.jpg";
import ActivitiesBanner from '../components/ActivitiesBanner';
import './Activities.css';

const Activities = () => {
    const [activities, setActivities] = useState([]);

    useEffect(() => {
        fetchActivities();
    }, []);

    const fetchActivities = async () => {
        try {
            const response = await axios.get('https://api.blissfullhimalaya.com/api/activities');
            setActivities(response.data);
        } catch (error) {
            console.error('Error fetching activities:', error.message);
        }
    };

    return (
        <>
        <ActivitiesBanner />
        <div className="activities">
            <section className='intro'>
                <div className="left-container">
                    <img src={Annapurna} alt="Nepal Landscape" className="bannerimage" />
                </div>
                <div className="right-container">
                    <h2>Discover Adventure in Nepal</h2>
                    <p>
                        At Blissful Himalaya, we invite you to embark on unforgettable adventures across Nepal. 
                        From thrilling trekking experiences to serene nature retreats, our curated activities offer 
                        something for every explorer. Immerse yourself in Nepal's breathtaking landscapes, rich culture, 
                        and diverse wildlife. Whether you're an adrenaline junkie or a nature enthusiast, our 
                        activities will ensure you experience the true essence of this magnificent country.
                    </p>
                </div>
            </section>
            <div className="activity-list-container" id="activity-list">
                {activities.length > 0 ? (
                    activities.map(activity => (
                        <div key={activity.uuid} className="activity-card">
                            <img src={`https://api.blissfullhimalaya.com/uploads/${activity.image}`} alt={activity.name} className="activity-image" />
                            <div className="activity-content">
                                <h3>{activity.name}</h3>
                                <p className="activity-description">
                                    {activity.description.length > 80
                                        ? `${activity.description.slice(0, 80)}...`
                                        : activity.description}
                                </p>
                                <Link to={`/activities/${activity.uuid}`} className="details-link">View Details</Link>
                            </div>
                        </div>
                    ))
                ) : (
                    <p className="no-activities">No activities available at the moment.</p>
                )}
            </div>
        </div>
        </>
    );
};

export default Activities;
