import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AdminGalleryPanel.css';

const AdminGalleryPanel = () => {
  const [galleries, setGalleries] = useState([]);
  const [form, setForm] = useState({ name: '', image: null });
  const [error, setError] = useState('');

  useEffect(() => {
    fetchGalleries();
  }, []);

  const fetchGalleries = async () => {
    try {
      const response = await axios.get('https://api.blissfullhimalaya.com/api/galleries');
      setGalleries(response.data);
    } catch (error) {
      console.error('Error fetching galleries:', error.message);
    }
  };

  const handleInputChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setForm({ ...form, image: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', form.name);
    if (form.image) {
      formData.append('image', form.image);
    }

    try {
      await axios.post('https://api.blissfullhimalaya.com/api/galleries', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      fetchGalleries();
      setForm({ name: '', image: null });
      setError(''); // Clear error message on success
    } catch (error) {
      setError('Error adding gallery: ' + (error.response ? error.response.data : error.message));
      console.error('Error adding gallery:', error.message);
    }
  };

  const handleDelete = async (uuid) => {
    try {
      await axios.delete(`https://api.blissfullhimalaya.com/api/galleries/${uuid}`);
      fetchGalleries();
      setError(''); // Clear error message on success
    } catch (error) {
      setError('Error deleting gallery: ' + error.message);
      console.error('Error deleting gallery:', error.message);
    }
  };

  return (
    <div className="center">
      <div className="admin-gallery-panel">
        <h1>Admin Gallery Panel</h1>
        {error && <p className="error-message">{error}</p>}
        <form onSubmit={handleSubmit}>
          <input
            name="name"
            value={form.name}
            onChange={handleInputChange}
            placeholder="Name"
            required
          />
          <input
            type="file"
            name="image"
            onChange={handleFileChange}
            required
          />
          <button type="submit">Add Gallery</button>
        </form>
        <div className="gallery-list">
          {galleries.map(gallery => (
            <div key={gallery.uuid} className="gallery-item">
              <h3>{gallery.name}</h3>
              <img 
                src={`https://api.blissfullhimalaya.com/uploads/${gallery.image}`} 
                alt={gallery.name} 
                className="gallery-image" 
              />
              <button onClick={() => handleDelete(gallery.uuid)}>Delete</button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AdminGalleryPanel;
