import React from 'react';
import './ActivitiesBanner.css';
import BannerImage from '../assets/banner.jpg';

const PlacesToGoBanner = () => {
  return (

    <div className="activities-banner">
      <img src={BannerImage} alt="Activities Banner" className="banner-image" />
      <div className="banner-content">
        <h1 className="banner-title">Discover Amazing Places</h1>
        <p className="banner-subtitle">Explore the most beautiful destinations around the world</p>
        <a href="#articlecount" className="banner-button">Explore Now</a>
      </div>
    </div>
  );
};

export default PlacesToGoBanner;