import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AdminActivityForm.css';

const AdminActivityForm = () => {
    const [activities, setActivities] = useState([]);
    const [form, setForm] = useState({ name: '', description: '', image: null, duration: '', price: '' });
    const [editingUuid, setEditingUuid] = useState(null);

    useEffect(() => {
        fetchActivities();
    }, []);

    const fetchActivities = async () => {
        try {
            const response = await axios.get('https://api.blissfullhimalaya.com/api/activities');
            setActivities(response.data);
        } catch (error) {
            console.error('Error fetching activities:', error.message);
        }
    }

    const handleInputChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    }

    const handleFileChange = (e) => {
        setForm({ ...form, image: e.target.files[0] });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('name', form.name);
        formData.append('description', form.description);
        formData.append('duration', form.duration);
        formData.append('price', form.price);
    
        if (form.image) {
            formData.append('image', form.image);
        }
    
        try {
            if (editingUuid) {
                // Update existing activity
                await axios.put(`https://api.blissfullhimalaya.com/api/activities/${editingUuid}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
            } else {
                // Add new activity
                await axios.post('https://api.blissfullhimalaya.com/api/activities', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
            }
            fetchActivities();
            setForm({ name: '', description: '', image: null, duration: '', price: '' });
            setEditingUuid(null);
        } catch (error) {
            console.error('Error submitting activity:', error.response ? error.response.data : error.message);
        }
    };
    

    const handleEdit = (activity) => {
        setForm({
            name: activity.name,
            description: activity.description,
            image: null,
            duration: activity.duration,
            price: activity.price
        });
        setEditingUuid(activity.uuid);
    };

    const handleDelete = async (uuid) => {
        try {
            await axios.delete(`https://api.blissfullhimalaya.com/api/activities/${uuid}`);
            fetchActivities();
        } catch (error) {
            console.error('Error deleting activity:', error.message);
        }
    }

    return (
        <div className="admin-activity-form">
            <h1>Admin Activity Panel</h1>
            <form onSubmit={handleSubmit}>
                <label>Name:</label>
                <input type="text" name="name" value={form.name} onChange={handleInputChange} required />
                <label>Description:</label>
                <textarea name="description" value={form.description} onChange={handleInputChange} required />
                <label>Image:</label>
                <input type="file" name="image" accept="image/*" onChange={handleFileChange} />
                <label>Duration:</label>
                <input type="text" name="duration" value={form.duration} onChange={handleInputChange} required />
                <label>Price:</label>
                <input type="number" name="price" value={form.price} onChange={handleInputChange} required />
                <button type="submit">{editingUuid ? 'Update Activity' : 'Add Activity'}</button>
            </form>
            <div className="activities-container">
                {activities.map(activity => (
                    <div key={activity.uuid} className="activity-card">
                        <img src={`https://api.blissfullhimalaya.com/uploads/${activity.image}`} alt={activity.name} />
                        <h3>{activity.name}</h3>
                        <p>{activity.description}</p>
                        <p>Price: ${activity.price}</p>
                        <div className="card-buttons">
                            <button onClick={() => handleEdit(activity)}>Edit</button>
                            <button onClick={() => handleDelete(activity.uuid)}>Delete</button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AdminActivityForm;
