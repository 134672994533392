import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AdminBlogPanel.css';

const AdminBlogPanel = () => {
  const [blogs, setBlogs] = useState([]);
  const [form, setForm] = useState({ title: '', image: '', description: '', date: '', writer: '' });
  const [editingUuid, setEditingUuid] = useState(null);

  useEffect(() => {
    fetchBlogs();
  }, []);

  const fetchBlogs = async () => {
    try {
      const response = await axios.get('https://api.blissfullhimalaya.com/api/blogs');
      setBlogs(response.data);
    } catch (error) {
      console.error('Error fetching blogs:', error.message);
    }
  };

  const handleInputChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (editingUuid) {
      // Update existing blog
      try {
        await axios.put(`https://api.blissfullhimalaya.com/api/blogs/${editingUuid}`, form);
        fetchBlogs();
        setForm({ title: '', image: '', description: '', date: '', writer: '' });
        setEditingUuid(null);
      } catch (error) {
        console.error('Error updating blog:', error.message);
      }
    } else {
      // Add new blog
      try {
        await axios.post('https://api.blissfullhimalaya.com/api/blogs', form);
        fetchBlogs();
        setForm({ title: '', image: '', description: '', date: '', writer: '' });
      } catch (error) {
        console.error('Error adding blog:', error.message);
      }
    }
  };

  const handleEdit = (blog) => {
    setForm(blog);
    setEditingUuid(blog.uuid);
  };

  const handleDelete = async (uuid) => {
    try {
      await axios.delete(`https://api.blissfullhimalaya.com/api/blogs/${uuid}`);
      fetchBlogs();
    } catch (error) {
      console.error('Error deleting blog:', error.message);
    }
  };

  return (
    <div className="admin-blog-panel">
      <h1>Admin Blog Panel</h1>
      <form onSubmit={handleSubmit}>
        <input name="title" value={form.title} onChange={handleInputChange} placeholder="Title" />
        <input name="image" value={form.image} onChange={handleInputChange} placeholder="Image URL" />
        <textarea name="description" value={form.description} onChange={handleInputChange} placeholder="Description"></textarea>
        <input name="date" value={form.date} onChange={handleInputChange} placeholder="Date" />
        <input name="writer" value={form.writer} onChange={handleInputChange} placeholder="Writer" />
        <button type="submit">{editingUuid ? 'Update Blog' : 'Add Blog'}</button>
      </form>
      <div className="blogs-container">
        {blogs.map(blog => (
          <div key={blog.uuid} className="blog-card">
            <img src={blog.image} alt={blog.title} />
            <h3>{blog.title}</h3>
            <div className="card-buttons">
              <button onClick={() => handleEdit(blog)}>Edit</button>
              <button onClick={() => handleDelete(blog.uuid)}>Delete</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminBlogPanel;
