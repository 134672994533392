// src/pages/AboutUs.js
import React from 'react';
import './AboutUs.css';
import AboutUsBanner from '../components/AboutUsBanner';

function AboutUs() {
  return (
    <> 
    <AboutUsBanner />
    <section className="about-us">
      <div className="container">
        <div className="about-summary">
          <p>
            Blissful Himalaya is an open team of spiritual knowledge, professionalism in sustainable tourism practice, and perfect planning and operation. We hire experienced in-house operators with field experience and emerging technical management capacity. Our team, local tourism professionals, is ready to respond to your queries about well-managed and reliable trek/tour experts. Our work is more than a job; it reflects our family bond. We are well-trained, friendly, and experienced in every corner of Nepal, having researched the geography, mountains, wildlife, and overall biodiversity. Our major goal is to provide information for travelers and organize unique travel experiences through our services. We prepare tailored itineraries for a hassle-free travel experience.
          </p>
        </div>
        <div className="team-section">
          <h2>Meet Our Team</h2>
          <div className="team-member-container">
            <div className="team-member">
              <div className="team-member-image">
                <img src="https://t4.ftcdn.net/jpg/03/64/21/11/360_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg" alt="Aashish Rijal" />
              </div>
              <div className="team-member-info">
                <h3>CEO / Operator / Blogger</h3>
                <blockquote>
                  <p>“Travel is above the fun and enjoyment, it’s mental retreat, finding ownself”</p>
                  <footer>— Aashish Rijal</footer>
                </blockquote>
                <p>
                  Mr. Aashish Rijal, traveler, writer, and nature researcher since half a decade on every nook and corner of Nepali geography. He explores by himself to check out the location, hospitality, and culture before writing articles and blogs to provide guidance for your individual journey. Since his early age, he has had a passion for traveling the natural inhabitants, exploring peaceful destinations for soulful retreats which he wants to reveal how beautiful Nepal is and how peaceful it is. Moreover, our CEO is a tourism graduate who has the potential to uplift tourism employment and create opportunities for locals. He often says, “our country is still untouched and undiscovered with full of thrills with unexpected pleasant of every single journey”.
                </p>
              </div>
            </div>
            <div className="team-member">
              <div className="team-member-image">
                <img src="https://st.depositphotos.com/15741256/61024/i/450/depositphotos_610243234-stock-photo-confident-positive-caucasian-businessman-employee.jpg" alt="Ajesh Gautam" />
              </div>
              <div className="team-member-info">
                <h3>Technical Manager / Operator</h3>
                <p><strong>Ajesh Gautam</strong></p>
                <p>
                  Mr. Gautam is a young IT professional who is a genius in technical terminology to serve the real information through our OTA platform. A genius and creative person based in India, who was born in Nepal and studied in New Delhi. As an explorer, he usually travels to several destinations and focuses on promoting hidden gems to create a bond between humans and nature through the digital platform.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  );
}

export default AboutUs;
