// src/pages/CustomerReviews.js
import React from 'react';
import './Reviews.css';

function Reviews() {
  const reviews = [
    {
      id: 1,
      name: "John Doe",
      review: "Blissful Himalaya made our trip unforgettable. The guides were knowledgeable, and the service was top-notch.",
      photoUrl: "https://media.istockphoto.com/id/1170953707/photo/smiling-black-man.jpg?s=612x612&w=0&k=20&c=lKqsRoMExYAnVtIw9fadM84rOPBhI_LVLCuRaBvstvo=",
    },
    {
      id: 2,
      name: "Jane Smith",
      review: "An amazing experience! The scenery was breathtaking and everything was well organized.",
      photoUrl: "https://media.istockphoto.com/id/1135381120/photo/portrait-of-a-young-woman-outdoors-smiling.jpg?s=612x612&w=0&k=20&c=T5dukPD1r-o0BFqeqlIap7xzw07icucetwKaEC2Ms5M=",
    },
    {
      id: 3,
      name: "Michael Brown",
      review: "Highly recommend! A perfect blend of adventure and relaxation.",
      photoUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5LN0SXKFZdaIQjgCwOoa24uZ2H8w-zyKVO-6q9XFMdDrdbSsmMUUuJ-nfK_9uskTboCM&usqp=CAU",
    },
  ];

  return (
    <section className="customer-reviews">
      <h2>Customer Reviews</h2>
      <div className="reviews-container">
        {reviews.map(review => (
          <div className="review-card" key={review.id}>
            <div className="reviewer-info">
              {review.photoUrl && <img src={review.photoUrl} alt={review.name} className="reviewer-photo" />}
              <h3 className="reviewer-name">{review.name}</h3>
            </div>
            <p className="review-text">"{review.review}"</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Reviews;
