import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AdminArticlePanel.css'; // Make sure to create this CSS file for styling

const AdminArticlePanel = () => {
    const [articles, setArticles] = useState([]);
    const [form, setForm] = useState({ 
        title: '', 
        images1: null, 
        images2: null, 
        images3: null, 
        images4: null, 
        description: '', 
        cost: '', 
        duration: '', 
        startPoint: '', 
        endPoint: '' 
    });
    const [editingUuid, setEditingUuid] = useState(null);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    useEffect(() => {
        fetchArticles();
    }, []);

    const fetchArticles = async () => {
        try {
            const response = await axios.get('https://api.blissfullhimalaya.com/api/articles');
            setArticles(response.data);
        } catch (error) {
            setError('Error fetching articles: ' + error.message);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        setForm({ ...form, [name]: files[0] });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        Object.keys(form).forEach(key => {
            if (form[key] !== null) {
                formData.append(key, form[key]);
            }
        });

        try {
            if (editingUuid) {
                await axios.put(`https://api.blissfullhimalaya.com/api/articles/${editingUuid}`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
                setSuccess('Article updated successfully!');
            } else {
                await axios.post('https://api.blissfullhimalaya.com/api/articles', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
                setSuccess('Article added successfully!');
            }
            setForm({ 
                title: '', 
                images1: null, 
                images2: null, 
                images3: null, 
                images4: null, 
                description: '', 
                cost: '', 
                duration: '', 
                startPoint: '', 
                endPoint: '' 
            });
            setEditingUuid(null);
            fetchArticles();
        } catch (error) {
            setError('Error submitting article: ' + error.message);
        }
    };

    const handleEditArticle = (article) => {
        setForm({
            title: article.title,
            images1: null,
            images2: null,
            images3: null,
            images4: null,
            description: article.description,
            cost: article.cost,
            duration: article.duration,
            startPoint: article.startPoint,
            endPoint: article.endPoint
        });
        setEditingUuid(article.uuid);
    };

    const handleDelete = async (uuid) => {
        try {
            await axios.delete(`https://api.blissfullhimalaya.com/api/articles/${uuid}`);
            setSuccess('Article deleted successfully!');
            fetchArticles();
        } catch (error) {
            setError('Error deleting article: ' + error.message);
        }
    };

    return (
        <div className="admin-article-panel">
            <h1>Admin Article Panel</h1>
            {error && <p className="error-message">{error}</p>}
            {success && <p className="success-message">{success}</p>}
            <form onSubmit={handleSubmit} encType="multipart/form-data">
                <div className="form-group">
                    <label>Title:</label>
                    <input
                        type="text"
                        name="title"
                        value={form.title}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Image1:</label>
                    <input
                        type="file"
                        name="images1"
                        onChange={handleFileChange}
                    />
                </div>
                <div className="form-group">
                    <label>Image2:</label>
                    <input
                        type="file"
                        name="images2"
                        onChange={handleFileChange}
                    />
                </div>
                <div className="form-group">
                    <label>Image3:</label>
                    <input
                        type="file"
                        name="images3"
                        onChange={handleFileChange}
                    />
                </div>
                <div className="form-group">
                    <label>Image4:</label>
                    <input
                        type="file"
                        name="images4"
                        onChange={handleFileChange}
                    />
                </div>
                <div className="form-group">
                    <label>Description:</label>
                    <textarea
                        name="description"
                        value={form.description}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Cost:</label>
                    <input
                        type="text"
                        name="cost"
                        value={form.cost}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Duration:</label>
                    <input
                        type="text"
                        name="duration"
                        value={form.duration}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Start Point:</label>
                    <input
                        type="text"
                        name="startPoint"
                        value={form.startPoint}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>End Point:</label>
                    <input
                        type="text"
                        name="endPoint"
                        value={form.endPoint}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <button type="submit">{editingUuid ? 'Update' : 'Add'} Article</button>
            </form>

            <h2>Articles List</h2>
            <ul>
                {articles.map((article) => (
                    <li key={article.uuid}>
                        <h3>{article.title}</h3>
                        <button onClick={() => handleEditArticle(article)}>Edit</button>
                        <button onClick={() => handleDelete(article.uuid)}>Delete</button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default AdminArticlePanel;
