import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './BlogList.css';
import BlogBanner from '../components/BlogBanner';

const BlogList = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    fetchBlogs();
  }, []);

  const fetchBlogs = async () => {
    try {
      const response = await axios.get('https://api.blissfullhimalaya.com/api/blogs');
      setBlogs(response.data);
    } catch (error) {
      console.error('Error fetching blogs:', error);
    }
  };

  return (
    <>
    <BlogBanner />
    <div className='blog'>
    <div className="blog-list-container">
      {blogs.length > 0 ? (
        blogs.map(blog => (
          <div key={blog.uuid} className="blog-post"> {/* Updated class name */}
            <img src={blog.image} alt={blog.title} className="blog-post-image" />
            <div className="blog-post-content"> {/* New container for content */}
              <h3>{blog.title}</h3>
              <p className="blog-post-description">
                {blog.description.slice(0, 80)} {/* Limit description to 50 words */}
                {blog.description.length > 80 && '... '} {/* Add ellipsis if truncated */}
              </p>
              <Link to={`/blog/${blog.uuid}`}>Read More</Link>
            </div>
          </div>
        ))
      ) : (
        <p>No blogs available.</p>
      )}
    </div>
    </div>
    </>
  );
};

export default BlogList;
