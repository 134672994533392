// src/pages/TrendingDestinations.js
import React from 'react';
import './TrendingDestinations.css';
import { Link } from 'react-router-dom';

function TrendingDestinations() {

  const images = {
    everestBaseCamp: require('../../assets/everestregion.jpg'),
    Lumbini: require('../../assets/lumbini.jpeg'),
    Chitwan: require('../../assets/chitwan.jpg'),
    Pokhara: require('../../assets/Pokhara.jpg'),
    Kath: require('../../assets/kathmandu.png'),
  }

  const destinations = [
    { id: 1, name: "Kathmandu Valley", description: "Explore the cultural heritage.", imageUrl: images.Kath },
    { id: 2, name: "Pokhara", description: "City of Lakes.", imageUrl: images.Pokhara },
    { id: 3, name: "Chitwan", description: "A wildlife adventure awaits.", imageUrl: images.Chitwan },
    { id: 4, name: "Lumbini", description: "Birthplace of Buddha.", imageUrl: images.Lumbini },
    { id: 5, name: "Everest", description: "The gateway to the Himalayas, Highest Peak.", imageUrl: images.everestBaseCamp },
  ];

  return (
    <section className="trending-destinations">
      <h2>Trending Destinations</h2>
      <div className="destination-grid">
        {destinations.map((destination) => (

          <div className="destination-item" key={destination.id} style={{ backgroundImage: `url(${destination.imageUrl})` }}>
            <Link to={`/article/${encodeURIComponent(destination.name)}`}>
              <div className="overlay">
                <h3>{destination.name}</h3>
                <p>{destination.description}</p>
              </div>
            </Link>
          </div>

        ))}
      </div>
    </section>
  );
}

export default TrendingDestinations;
