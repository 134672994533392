import React, {useState, useEffect} from "react";
import axios from "axios";
import {useParams} from "react-router-dom";
import './ActivityDetails.css';

const ActivityDetails = () => {
    const {uuid} = useParams();
    const [activity, setActivity] = useState(null);

    useEffect(() => {
        fetchActivity();
    }, []);

    const fetchActivity = async () => {
        try {
            const response = await axios.get(`https://api.blissfullhimalaya.com/api/activities/${uuid}`);
            setActivity(response.data);
        } catch (error) {
            console.error('Error fetching activity:', error);
        }
    };

    if (!activity) return <p>Loading...</p>;

    return (
        <div className="activity-detail-container">
            <h1>{activity.name}</h1>
            <img src={`https://api.blissfullhimalaya.com/uploads/${activity.image}`} alt={activity.name} className="activity-detail-image" />
            <p>{activity.description}</p>
            <p><strong>Duration:</strong> {activity.duration}</p>
            <p><strong>Price:</strong> {activity.price}</p>
        </div>
    );
};

export default ActivityDetails;
