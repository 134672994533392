// src/pages/Home.js
import React from 'react';
import SeasonalHighlights from './HomePageCont/SeasonalHighlights';
import TrendingDestinations from './HomePageCont/TrendingDestinations';
import UpcomingEvents from './HomePageCont/UpcomingEvents';
import Reviews from './HomePageCont/Reviews';
import LatestBlog from './HomePageCont/LatestBlog';
import Banner from '../components/Banner';


function Home() {
  return (
    <div className="home">
      <Banner />
      <SeasonalHighlights />
      <TrendingDestinations />
      <UpcomingEvents />
      <Reviews />
      <LatestBlog />
    </div>
  );
}

export default Home;
