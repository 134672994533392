import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './UpcomingEvents.css';

const UpcomingEvents = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      const response = await axios.get('https://api.blissfullhimalaya.com/api/events');
      setEvents(response.data);
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  };

  return (
    <div className="events-list">
      <h1>Upcoming Events</h1>
      <div className="events-list-container">
        {events.length > 0 ? (
            events.map(event => (
              <div key={event.uuid} className="event-card">
                <img src={event.image} alt={event.title} />
                <h3>{event.title}</h3>
                <p>
                  {event.description.length > 80
                    ? `${event.description.substring(0, 100)}...`
                    : event.description}
                </p>
                <Link to={`/events/${event.uuid}`} className="read-more">Read More</Link>
              </div>
            ))
      ) : (
          <p>No events available.</p>
        )}
      </div>
    </div>
  );
};

export default UpcomingEvents;
