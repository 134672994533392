// src/components/Footer.js
import React from 'react';
import './Footer.css';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn, FaYoutube } from 'react-icons/fa';
import logo from '../assets/logo.jpeg';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section logo-section">
          <img src={logo} alt="Blissful Himalaya Logo" className="footer-logo" />
        </div>
        <div className="footer-section contact-section">
          <h3>Contact Us</h3>
          <p className="address">Thamel, Kathmandu, Nepal</p>
          <p className="contact-info">Phone: +977 986-2253793</p>
          <p className="contact-info">Email: admin@blissfulhimalaya.com</p>
        </div>
        <div className="footer-section follow-section">
          <h3>Follow Us</h3>
          <div className="social-links">
            <a href="https://www.facebook.com/profile.php?id=61561183425468&mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer" className="social-link">
              <FaFacebookF className="social-icon" />
            </a>
            <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" className="social-link">
              <FaTwitter className="social-icon" />
            </a>
            <a href="https://www.instagram.com/blissfullhimalaya?igsh=MW1tOGxpbWZsaDFkZA%3D%3D&utm_source=qr" target="_blank" rel="noopener noreferrer" className="social-link">
              <FaInstagram className="social-icon" />
            </a>
            <a href="https://www.linkedin.com/in/aashish-rijal-26173b276/" target="_blank" rel="noopener noreferrer" className="social-link">
              <FaLinkedinIn className="social-icon" />
            </a>
            <a href="https://youtube.com/@blissfullhimalaya?si=BmIlOQv47jf_ZddX" target="_blank" rel="noopener noreferrer" className="social-link">
              <FaYoutube className="social-icon" />
            </a>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Blissful Himalaya. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
