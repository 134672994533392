import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import './Header.css';
import logo from '../assets/logo.jpeg';

function Header() {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const toggleMobileMenu = () => setMobileMenuOpen(!isMobileMenuOpen);
  
  return (
    <header className="header">
      <div className="logo">
        <img src={logo} alt="Blissful Himalaya Logo" className="header-logo" />
        <Link to="/">Blissfull Himalaya</Link>
      </div>
      <div className="menu-toggle" onClick={toggleMobileMenu}>
        {isMobileMenuOpen ? <FaTimes className="menu-icon" /> : <FaBars className="menu-icon" />}
      </div>
      <nav className={`nav ${isMobileMenuOpen ? 'open' : ''}`}>
        <ul>
          <li><Link to="/articles" className="nav-link">Places-to-go</Link></li>
          <li><Link to="/activities" className="nav-link">Activities</Link></li>
          <li><Link to="/about-us" className="nav-link">About Us</Link></li>
          <li><Link to="/blog" className="nav-link">Blog</Link></li>
          <li><Link to="/gallery" className="nav-link">Gallery</Link></li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
