// src/BlogDetail.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './BlogDetail.css';

const BlogDetail = () => {
  const { uuid } = useParams();
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    fetchBlog();
  }, [uuid]);

  const fetchBlog = async () => {
    try {
      const response = await axios.get(`https://api.blissfullhimalaya.com/api/blogs/${uuid}`);
      setBlog(response.data);
    } catch (error) {
      console.error('Error fetching blog:', error);
    }
  };

  if (!blog) return <p>Loading...</p>;

  return (
    <div className="blog-detail-container">
      <h1>{blog.title}</h1>
      <img src={blog.image} alt={blog.title} className="blog-detail-image" />
      <p><strong>Date:</strong> {blog.date}</p>
      <p><strong>Writer:</strong> {blog.writer}</p>
      <p>{blog.description}</p>
    </div>
  );
};

export default BlogDetail;
