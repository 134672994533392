import React from 'react';
import BlogBannerImage from '../assets/banner.jpg';
import './BlogBanner.css';

const BlogBanner = () => {
  return (
    <div className="blog-banner">
      <img src={BlogBannerImage} alt="Blog Banner" className="banner-image" />
      <div className="banner-content">
        <h1 className="banner-title">Our Blog</h1>
        <p className="banner-subtitle">Insights, Stories, and Travel Tips</p>
        <p className="banner-description">
          Dive into our blog to explore a collection of travel stories, expert tips, and insights from our adventures. Whether you're looking for destination guides, travel tips, or personal stories, our blog is your go-to resource for all things travel.
        </p>
      </div>
    </div>
  );
};

export default BlogBanner;
