import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AdminEventPanel.css';

const AdminEventPanel = () => {
  const [events, setEvents] = useState([]);
  const [event, setEvent] = useState({ title: '', image: '', date: '', description: '' });
  const [uuid, setUuid] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      const response = await axios.get('https://api.blissfullhimalaya.com/api/events');
      setEvents(response.data);
    } catch (error) {
      console.error('Error fetching events:', error);
      setError('Error fetching events. Please try again later.');
    }
  };

  const handleChange = (e) => {
    setEvent({ ...event, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (uuid) {
        // Update existing event
        await axios.put(`https://api.blissfullhimalaya.com/api/events/${uuid}`, event);
      } else {
        // Add new event
        await axios.post('https://api.blissfullhimalaya.com/api/events', event);
      }
      setEvent({ title: '', image: '', date: '', description: '' });
      setUuid(null);
      setError(''); // Clear any previous error
      fetchEvents();
    } catch (error) {
      console.error('Error submitting event:', error);
      setError('Error submitting event. Please try again.');
    }
  };

  const handleEdit = (eventToEdit) => {
    setEvent(eventToEdit);
    setUuid(eventToEdit.uuid);
  };

  const handleDelete = async (uuid) => {
    try {
      await axios.delete(`https://api.blissfullhimalaya.com/api/events/${uuid}`);
      fetchEvents();
    } catch (error) {
      console.error('Error deleting event:', error);
      setError('Error deleting event. Please try again.');
    }
  };

  return (
    <div className="admin-event-panel">
      <h1>{uuid ? 'Update Event' : 'Add Event'}</h1>
      {error && <p className="error-message">{error}</p>}
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="title"
          placeholder="Event Title"
          value={event.title}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="image"
          placeholder="Event Image URL"
          value={event.image}
          onChange={handleChange}
          required
        />
        <input
          type="date"
          name="date"
          placeholder="Event Date"
          value={event.date}
          onChange={handleChange}
          required
        />
        <textarea
          name="description"
          placeholder="Event Description"
          value={event.description}
          onChange={handleChange}
          required
        />
        <button type="submit">{uuid ? 'Update Event' : 'Add Event'}</button>
      </form>
      <div className="event-list">
        {events.map((eventItem) => (
          <div key={eventItem.uuid} className="event-item">
            <h3>{eventItem.title}</h3>
            <img src={eventItem.image} alt={eventItem.title} className="event-image" />
            <p>Date: {eventItem.date}</p>
            <p>{eventItem.description}</p>
            <button onClick={() => handleEdit(eventItem)}>Edit</button>
            <button onClick={() => handleDelete(eventItem.uuid)}>Delete</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminEventPanel;
