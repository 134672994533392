import React from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import './SeasonalHighlights.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SeasonalHighlights = () => {
  const images = {
    everestBaseCamp: require('../../assets/Everest.jpg'),
    Annapurna: require('../../assets/Annapurna.jpg'),
    Chitwan: require('../../assets/chitwan.jpg'),
    Pokhara: require('../../assets/Pokhara.jpg'),
    Rara: require('../../assets/Rara.jpg'),
  };

  const highlights = [
    { id: 1, name: "Everest Base Camp", imageUrl: images.everestBaseCamp },
    { id: 2, name: "Annapurna Circuit", imageUrl: images.Annapurna },
    { id: 3, name: "Rara", imageUrl: images.Rara },
    { id: 4, name: "Pokhara", imageUrl: images.Pokhara },
    { id: 5, name: "Chitwan National Park", imageUrl: images.Chitwan },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        }
      }
    ]
  };

  return (
    <section className="seasonal-highlights">
      <h2>Seasonal Highlights</h2>
      <p>
        The best part of Nepal is its variety of climates and season-specific destinations. Most tourists visit during spring and autumn for the perfect weather, although some choose to explore during the off-seasons like monsoon. Nepal experiences five major seasons: Spring (Mid March to Mid May), Summer (Mid May to Mid July), Monsoon (Mid July to September), Autumn (Mid September to Mid November), and Winter (Mid November to Mid March). These highlights should help you choose your travel destination based on your preferred season.
      </p>
      
      <Slider {...settings}>
        {highlights.map((highlight) => (
          <div className="highlight-item" key={highlight.id}>
            <Link to={`/article/${encodeURIComponent(highlight.name)}`}>
              <img src={highlight.imageUrl} alt={highlight.name} />
              <div className="highlight-overlay">
                <h3>{highlight.name}</h3>
              </div>
            </Link>
          </div>
        ))}
      </Slider>
    </section>
  );
};

export default SeasonalHighlights;
