// src/components/Banner.js
import React from 'react';
import './Banner.css';
import SearchBar from './Search';

function Banner() {
  return (
    <div className="banner">
      <div className="banner-content">
        <h1>“Discover the hidden sides of Nepal with us”</h1>
        <p>Blissful Himalaya is a cost-free online travel information desk that assists you in finding your desirable holiday plans.</p>
        <div className="BannerSearchContainer">
          <SearchBar />
        </div>
      </div>
    </div>
  );
}

export default Banner;
