// src/pages/PlacesToGo.js
import React, { useState, useEffect } from 'react';
import './PlacesToGo.css';
import PTGBANNER from '../components/PlacesToGoBanner';
import Annapurna from '../assets/Annapurna.jpg';
import { Link } from "react-router-dom";
import axios from 'axios';

function PlacesToGo() {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    fetchArticles();
  }, []);

  const fetchArticles = async () => {
    try {
      const response = await axios.get('https://api.blissfullhimalaya.com/api/articles');
      setArticles(response.data);
    } catch (error) {
      console.error('Error fetching articles:', error);
    }
  };

  return (
    <>
      <PTGBANNER />
    <div className='ptg'>
      <section className='article'>
        <div className="left-container">
          <img src={Annapurna} alt="Places to Go" className="bannerimage" />
        </div>
        <div className="right-container">
          <h2>Explore Nepal</h2>
          <p>
            At Blissful Himalaya, we offer an in-depth look at the stunning destinations across Nepal.
            Our articles provide rich insights into the diverse landscapes, vibrant cultures, and hidden
            gems of this enchanting country. Whether you're planning your next adventure or simply dreaming
            of the Himalayas, our expertly crafted content will guide you through the best places Nepal has to offer.
            Dive in and discover the magic of Nepal, one article at a time.
          </p>
        </div>
      </section>
      <section className='articlelist' id='articlecount'>
        <div className="articlelist-container">
          {articles.length > 0 ? (
            articles.map(article => (
              <div key={article.uuid} className="articlelist-post">
                <div className="articlelist-post-content">
                  <h3>{article.title}</h3>
                  <p className="articlelist-post-description">
                    {article.description.length > 80
                      ? `${article.description.slice(0, 80)}...`
                      : article.description}
                  </p>
                  <Link to={`/article/${article.title}`} className="read-more-link">Read More</Link>
                </div>
              </div>
            ))
          ) : (
            <p className="no-articles">No articles available.</p>
          )}
        </div>
      </section>
    </div>
    </>
  );
}

export default PlacesToGo;
