import React from 'react';
import { Link } from 'react-router-dom';
import './AdminPanel.css';

function AdminPanel() {
  return (
    <div className="admin-panel">
      <h1>Admin Panel</h1>
      <nav>
        <ul>
          <li className='admin-link'><Link to="/admin/blog-manager">Manage Blogs</Link></li>
          <li className='admin-link'><Link to="/admin/gallery-manager">Manage Gallery</Link></li>
          <li className='admin-link'><Link to="/admin/event-manager">Manage Events</Link></li>
          <li className='admin-link'><Link to="/admin/activities">Manage Activities</Link></li>
          <li className='admin-link'><Link to="/admin/articles">Manage Articles</Link></li>
        </ul>
      </nav>
    </div>
  );
}

export default AdminPanel;
