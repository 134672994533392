// src/pages/ArticleDetails.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './ArticleDetails.css';

const ArticleDetails = () => {
    const { title } = useParams();
    const [article, setArticle] = useState(null);
    const [error, setError] = useState('');

    useEffect(() => {
        fetchArticle();
    }, [title]);

    const fetchArticle = async () => {
        try {
            const response = await axios.get(`https://api.blissfullhimalaya.com/api/articles/${title}`);
            setArticle(response.data);
        } catch (error) {
            console.error('Error fetching article:', error);
            setError('Failed to load article. Please try again later.');
        }
    };

    if (error) return <p className="error-message">{error}</p>;
    if (!article) return <p>Loading...</p>;

    return (
        <div className="article-detail-container">
            <h1 className="article-title">{article.title}</h1>
            <div className="article-images">
                {article.images1 && <img src={`https://api.blissfullhimalaya.com/uploads/${article.images1}`} alt="Gallery Image 1" className="article-detail-image" />}
                {article.images2 && <img src={`https://api.blissfullhimalaya.com/uploads/${article.images2}`} alt="Gallery Image 2" className="article-detail-image" />}
                {article.images3 && <img src={`https://api.blissfullhimalaya.com/uploads/${article.images3}`} alt="Gallery Image 3" className="article-detail-image" />}
                {article.images4 && <img src={`https://api.blissfullhimalaya.com/uploads/${article.images4}`} alt="Gallery Image 4" className="article-detail-image" />}
            </div>
            <div className="article-content">
                <p>{article.description}</p>
                <p><strong>Total Cost:</strong> ${article.cost}</p>
                <p><strong>Duration:</strong> {article.duration}</p>
                <p><strong>Start Point:</strong> {article.startPoint}</p>
                <p><strong>End Point:</strong> {article.endPoint}</p>
            </div>
        </div>
    );
}

export default ArticleDetails;
