import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './LatestBlogPosts.css';
import axios from 'axios';

const LatestBlogPosts = () =>{
  const [blogPosts, setBlogPosts] = useState([]);

  useEffect(() => {
    fetchBlogs();
  }, []);

  const fetchBlogs = async () => {
    try {
      const response = await axios.get('https://api.blissfullhimalaya.com/api/latest-blogs');
      setBlogPosts(response.data);
    } catch (error) {
      console.error('Error fetching blogs:', error);
    }
  };

  return (
    <section className="latest-blog-posts">
    <>
    <h1>Latest Blogs</h1>
    <div className="blog-list-container">
      {blogPosts.length > 0 ? (
        blogPosts.map(blog => (
          <div key={blog.uuid} className="post"> 
            <img src={blog.image} alt={blog.title} />
            <div className="content"> {/* New container for content */}
              <h3>{blog.title}</h3>
              <p className="description">
                {blog.description.slice(0, 80)} {/* Limit description to 50 words */}
                {blog.description.length > 80 && '... '} {/* Add ellipsis if truncated */}
              </p>
              <Link to={`/blog/${blog.uuid}`}>Read More</Link>
            </div>
          </div>
        ))
      ) : (
        <p>No blogs available.</p>
      )}
    </div>
    </>
    </section>
  );
}

export default LatestBlogPosts;
