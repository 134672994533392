import React, { useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import PlacesToGo from './pages/PlacesToGo';
import AboutUs from './pages/AboutUs';
import BlogList from './pages/BlogList';
import AdminPanel from './admin/AdminPanel';
import AdminBlogPanel from './admin/AdminBlogPanel';
import AdminGalleryPanel from './admin/AdminGalleryPanel';
import BlogDetail from './pages/BlogDetail';
import AdminEventPanel from './admin/AdminEventPanel';
import EventDetail from './pages/HomePageCont/EventDetail';
import ArticleDetails from './pages/ArticleDetails';
import GalleryList from './pages/GalleryList';
import GalleryDetail from './pages/GalleryDetail';
import Activities from './Activity/Activities';
import ActivityDetails from './Activity/ActivityDetails';
import AdminActivityForm from './admin/AdminActivityForm';
import AdminArticlePanel from './admin/AdminArticlePanel';
import Login from './components/Login';
import './App.css';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/articles" element={<PlacesToGo />} />
        <Route path="/article/:title" element={<ArticleDetails />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/blog" element={<BlogList />} />
        <Route path="/blog/:uuid" element={<BlogDetail />} />
        <Route path="/events/:uuid" element={<EventDetail />} />
        <Route path="/gallery" element={<GalleryList />} />
        <Route path="/gallery/:uuid" element={<GalleryDetail />} />
        <Route path="/activities" element={<Activities />} />
        <Route path="/activities/:uuid" element={<ActivityDetails />} />
        <Route path="/admin/articles" element={isAuthenticated ? <AdminArticlePanel /> : <Navigate to="/login" />} />
        <Route path="/admin/blog-manager" element={isAuthenticated ? <AdminBlogPanel /> : <Navigate to="/login" />} />
        <Route path="/admin/gallery-manager" element={isAuthenticated ? <AdminGalleryPanel /> : <Navigate to="/login" />} />
        <Route path="/admin/event-manager" element={isAuthenticated ? <AdminEventPanel /> : <Navigate to="/login" />} />
        <Route path="/admin/activities" element={isAuthenticated ? <AdminActivityForm /> : <Navigate to="/login" />} />
        <Route path="/admin" element={isAuthenticated ? <AdminPanel /> : <Navigate to="/login" />} />
        <Route path="/login" element={isAuthenticated ? <Navigate to="/admin" /> : <Login onLogin={handleLogin} />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
