// src/pages/GalleryList.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './GalleryList.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const GalleryList = () => {
  const [galleries, setGalleries] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchGalleries();
  }, []);

  const fetchGalleries = async () => {
    try {
      const response = await axios.get('https://api.blissfullhimalaya.com/api/galleries');
      setGalleries(response.data);
    } catch (error) {
      setError('Error fetching galleries: ' + error.message);
    }
  };

  // Slider settings
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <>
      <div className="gallerybanner">
        {galleries.length > 0 ? (
          <Slider {...sliderSettings}>
            {galleries.map(gallery => (
              <div key={gallery.uuid} className="banner-slide">
                <img
                  className="banner-image"
                  src={`https://api.blissfullhimalaya.com/uploads/${gallery.image}`}
                  alt={gallery.name}
                />
                <p className="banner-text">{gallery.name}</p>
              </div>
            ))}
          </Slider>
        ) : (
          <p className="nogalleries">No galleries available.</p>
        )}
      </div>
      <div className='gallerylist'>
        {error && <p className="error-message">{error}</p>}
        <div className="gallerylistcontainer">
          {galleries.length > 0 ? (
            galleries.map(gallery => (
              <div key={gallery.uuid} className="galleryitem">
                <Link to={`/gallery/${gallery.uuid}`}>
                  <img
                    className="galleryimage"
                    src={`https://api.blissfullhimalaya.com/uploads/${gallery.image}`}
                    alt={gallery.name}
                  />
                </Link>
              </div>
            ))
          ) : (
            <p className="no galleries">No galleries available.</p>
          )}
        </div>
      </div>
    </>
  );
};

export default GalleryList;
