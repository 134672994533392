import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './GalleryDetail.css';

const GalleryDetail = () => {
  const { uuid } = useParams();
  const [gallery, setGallery] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchGallery = async () => {
      try {
        const response = await axios.get(`https://api.blissfullhimalaya.com/api/galleries/${uuid}`);
        setGallery(response.data);
      } catch (error) {
        setError('Error fetching gallery: ' + error.message);
        console.error('Error fetching gallery:', error);
      }
    };

    fetchGallery();
  }, [uuid]);

  if (error) {
    return <p className="error-message">{error}</p>;
  }

  if (!gallery) {
    return <p>Loading...</p>;
  }

  return (
    <div className="gallery-detail">
      <h1>{gallery.name}</h1>
      <img
        src={`https://api.blissfullhimalaya.com/uploads/${gallery.image}`}
        alt={gallery.name}
        className="gallery-detail-image"
      />
      <p>{gallery.description}</p>
    </div>
  );
};

export default GalleryDetail;
